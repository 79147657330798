import styled from "styled-components"
import { device } from "breakpoints"

export const Wrapper = styled.div`
  display: flex;
  max-width: 960px;
  padding-top: ${(props) => (props.isBottom ? "16px" : "0px")};
  padding-bottom: ${(props) => (props.isBottom ? "0px" : "16px")};
  justify-content: space-between;
  border-bottom: ${(props) => !props.isBottom && "1px solid #ced0d2"};
  border-top: ${(props) => props.isBottom && "1px solid #ced0d2"};
  margin: 24px auto;
  @media ${device.desktop} {
    padding-top: ${(props) => (props.isBottom ? "24px" : "0px")};
    padding-bottom: ${(props) => (props.isBottom ? "0px" : "24px")};
    margin: 60px auto;
  }
`

export const DownloadPDFButton = styled.a`
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  point-events: cursor;
  transition: 200ms;
  @media ${device.desktop} {
    font-size: 16px;
  }

  :hover {
    color: #1870b0;
  }
`

export const FileSize = styled.a`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  strong {
    font-weight: 500;
  }
  opacity: 1;
  transition: 200ms;

  @media ${device.desktop} {
    font-size: 14px;
  }

  :hover {
    opacity: 0.8;
  }
`
