import React from "react"
import { DownloadPDFButton, FileSize, Wrapper } from "./style"
import { Icon } from "UI"

// function downloadPDF(pdfUrl, fileName) {
//   const link = document.createElement("a")
//   link.href = pdfUrl
//   link.download = fileName // You can specify a file name here
//   document.body.appendChild(link)
//   link.click()
//   document.body.removeChild(link) // Clean up after download
// }

const DownloadPDF = ({ isBottom, mediaItemUrl, fileSize, title }) => {
  if (!mediaItemUrl) return null
  const kb = Math.round(fileSize / 1000)

  //   onClick={() => downloadPDF(mediaItemUrl, `${title}.pdf`)}
  return (
    <Wrapper isBottom={isBottom}>
      <DownloadPDFButton href={mediaItemUrl} target="_blank">
        <Icon name="acrobat" size="24px" />
        Download PDF version
      </DownloadPDFButton>
      <FileSize href={mediaItemUrl} target="_blank">
        <span>
          <strong>File size:</strong> {kb}KB
        </span>
        <Icon name="download" size="24px" />
      </FileSize>
    </Wrapper>
  )
}

export default DownloadPDF
