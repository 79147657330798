import React from "react"
import { LogoAreaContainer, Logo, LogoAreaText, TextContainer } from "./style"

const LogoArea = ({ text, logo }) => {
  if (!text && !logo) return null

  return (
    <LogoAreaContainer>
      <Logo fluid={logo} alt={text} />
      <TextContainer>
        <LogoAreaText as="p">{text}</LogoAreaText>
      </TextContainer>
    </LogoAreaContainer>
  )
}

export default LogoArea
