import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { CaseStudyTemplate } from "components/Templates/CaseStudyTemplate"

const Page = ({ data }) => {
  console.log("data:", data)
  const { seo, featuredImage } = data.page

  return (
    <Layout variant="casestudy" pageContext={data.page} key={data.page.uri}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />
      <CaseStudyTemplate
        data={{
          ...data.page,
          ...data.wp,
        }}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      uri
      avCaseStudies {
        caseStudyPdf {
          ... on WpMediaItem {
            title
            fileSize
            mediaItemUrl
          }
        }
      }
      ancestors {
        nodes {
          ... on WpPage {
            depth
            title
            uri
          }
        }
      }
      seo {
        title
        metaDesc
      }
      headerSettings {
        customPageTitle
        headerText
        videoModal
        logoArea {
          logo {
            sourceUrl
          }
          text
        }
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
      pageSettings {
        relatedHeading
        hideRelatedPages
        relatedPages {
          ... on WpPage {
            title
            excerpt
            uri
            featuredImage {
              node {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }

    wp {
      siteOptions {
        siteSettings {
          siteRelatedPages {
            heading
            defaultPages {
              ... on WpPage {
                title
                excerpt
                uri
              }
            }
            viewAllPage {
              ... on WpPage {
                uri
              }
            }
          }

          pgResource {
            ... on WpPage {
              uri
            }
          }
        }
      }
    }
  }
`
