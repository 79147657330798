import React, { useContext } from "react"
import { parseBlockContent } from "helpers/parseBlockContent"
import { PreviewContext } from "context/PreviewContext"
import { Content } from "UI"
import { InternalTemplateContainer } from "./style"
import { LatestResources } from "components/Project/LatestResources"
import { MainColumn, Spacer } from "UI"
import { LogoArea } from "../../Project/LogoArea"
import { DownloadPDF } from "./DownloadPDF"

const InternalTemplate = ({ data }) => {
  const { content, headerSettings, pageSettings, avCaseStudies } = data
  console.log("avCaseStudies:", avCaseStudies)

  const { previewData } = useContext(PreviewContext)

  const parsedContent =
    previewData?.templateData?.content || content
      ? parseBlockContent(content)
      : null

  const pageSettingsData =
    previewData?.templateData?.pageSettings || data.pageSettings

  return (
    <InternalTemplateContainer>
      <MainColumn>
        <LogoArea {...headerSettings?.logoArea} />
        <DownloadPDF {...avCaseStudies?.caseStudyPdf} />
        <Content>{parsedContent}</Content>
        <DownloadPDF isBottom {...avCaseStudies?.caseStudyPdf} />

        <Spacer height={[null, 30, 45]} />
      </MainColumn>

      {!pageSettingsData?.hideRelatedPages && (
        <LatestResources
          noMarginTop
          // variant="indexTemplate"
          handpickedData={{ items: pageSettings?.relatedPages }}
          heading={pageSettings?.relatedHeading}
        />
      )}
    </InternalTemplateContainer>
  )
}

export default InternalTemplate
