import styled from "styled-components"
import { device } from "breakpoints"
import { Box, Text } from "UI"
import AvenueImg from "UI/AvenueImg"

export const LogoAreaContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1870b0;
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  @media ${device.desktop} {
    flex-direction: row;
    padding: 24px;
    margin-bottom: 60px;
    gap: 24px;
  }
`

export const Logo = styled(AvenueImg)`
  max-width: 170px;
  max-height: 100px;
  width: 100%;
  height: 100%;

  @media ${device.desktop} {
    max-width: 212px;
    max-height: 124px;
  }
`
export const LogoAreaText = styled(Text)`
  color: white;
  text-align: center;
  @media ${device.desktop} {
    text-align: left;
    font-size: 20px;
  }
`

export const TextContainer = styled(Box)`
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid #008cd1;

  @media ${device.desktop} {
    padding-left: 48px;
    border-left: 1px solid #008cd1;
    border-top: none;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 71px;
    margin-top: 0;
  }
`
